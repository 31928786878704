import React from "react"

// gatsby libraries
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import InfoArea from "components/InfoArea/InfoArea.jsx"
import SvgIcon from "@material-ui/core/SvgIcon"

// @fortawesome icons
import {
  faPepperHot,
  faStore,
  faTrophy,
} from "@fortawesome/pro-solid-svg-icons"

// page styles
import contestsStyle from "assets/jss/material-kit-pro-react/views/registrationPageSections/contestsStyle.jsx"
const useStyles = makeStyles(contestsStyle)

export default function SectionContests() {
  const {
    casiCookOff,
    traditionalCookOff,
    salsaContest,
    showmanshipContest,
  } = useStaticQuery(graphql`
    query getContestsImages {
      casiCookOff: file(
        relativePath: { eq: "registration/casi-register.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      traditionalCookOff: file(
        relativePath: { eq: "registration/traditional-register.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      salsaContest: file(
        relativePath: { eq: "registration/salsa-register.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      showmanshipContest: file(
        relativePath: { eq: "registration/showmanship-register.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center" alignItems="center">
          <GridItem md={4} sm={8} xs={10} className={classes.contestsImageItem}>
            <GatsbyImage
              image={casiCookOff.childImageSharp.gatsbyImageData}
              imgStyle={{ objectFit: "contain" }}
              className={classes.contestsImage}
              alt="CASI Chili Cook-off"
            />
          </GridItem>
          <GridItem xs={12} sm={10} md={6} className={classes.contestsTextItem}>
            <InfoArea
              className={classes.infoArea}
              title="CASI Cook-off"
              description={
                <p>
                  Chilimania is once again proud to host the Wisconsin State
                  Championship CASI Cook-off, with the top placing chilies
                  earning trophies as well as points toward the CASI National
                  Cook-off in Terlingua, Texas.
                </p>
              }
              faIcon={faTrophy}
              iconColor="secondary"
            />
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="center" alignItems="center">
          <GridItem xs={12} sm={10} md={6} className={classes.contestsTextItem}>
            <InfoArea
              className={classes.infoArea}
              title="Traditional Chili Cook-off"
              description={
                <p>
                  Along with the CASI cook-off, we will have our traditional
                  cook-off with cash prizes and trophies. You may enter either
                  or both of the cook-offs. Please note that CASI cooking and
                  judging rules will apply for the CASI cook-off.
                </p>
              }
              faIcon={faPepperHot}
              iconColor="secondary"
            />
          </GridItem>
          <GridItem md={4} sm={8} xs={10} className={classes.contestsImageItem}>
            <GatsbyImage
              image={traditionalCookOff.childImageSharp.gatsbyImageData}
              imgStyle={{ objectFit: "contain" }}
              className={classes.contestsImage}
              alt="Traditional Chili Cook-off"
            />
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="center" alignItems="center">
          <GridItem md={4} sm={8} xs={10} className={classes.contestsImageItem}>
            <GatsbyImage
              image={salsaContest.childImageSharp.gatsbyImageData}
              className={classes.contestsImage}
              alt="Salsa Contest"
            />
          </GridItem>
          <GridItem
            xs={12}
            sm={10}
            md={6}
            className={classes.contestsTextSvgItem}
          >
            <div className={classes.customIconWrapper}>
              <SvgIcon
                className={classes.icon}
                id="Capa_1"
                data-name="Capa 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 486.98 512"
              >
                <title>tomato</title>
                <path
                  d="M460.05,183.27c-26.06,13.86-56.61,17.87-88.21,9,3.28,35.33-7,60.36-20.56,86.5-46.63-3.05-77.13-20.7-95.27-43.19C238,258,207.51,275.74,160.73,278.8c-12.48-24-24-49.41-20.56-86.5-30.3,8.52-61.16,5.37-88.2-9.05C-7.49,267.56-2.32,385.9,85,456.37c91.58,73.87,249.6,74.48,341.93,0C514.15,386.05,519.61,267.73,460.05,183.27Zm-19.22-23.88c-50.43-50.24-106.34-74.24-170.29-77.7,0-28.12,8.14-53.07,36-51.63L308.08.11C262-2.27,240.51,35,240.51,81.74c-67.17,3.87-121.67,30.16-169.33,77.65,47.47,19,74.54-.44,120.57-24.07-16.51,37-33.22,63.82-13.39,111.39,56.62-11.6,63.7-45.54,77.65-78.23,14.69,34.44,21.88,67.2,77.63,78.28,20.62-49.3,1.91-77.18-13.46-111.63C365.43,158.53,392.83,178.55,440.83,159.39Z"
                  transform="translate(-12.51 0)"
                />
              </SvgIcon>
            </div>
            <InfoArea
              className={classes.infoArea}
              title="Salsa Contest"
              description={
                <p>
                  The homemade salsa judging will be more in line with the chili
                  judging procedures. One-pint entries will be brought to the
                  judging tent for a formal tasting. A cash prize is available
                  for the first-place salsa.
                </p>
              }
              icon={""}
              iconColor="secondary"
            />
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="center" alignItems="center">
          <GridItem xs={12} sm={10} md={6} className={classes.contestsTextItem}>
            <InfoArea
              className={classes.infoArea}
              title="Showmanship Contest"
              description={
                <p>
                  Join in the fun of decorating your booth for a chance to win
                  the Best Booth award (and a cash prize). CASI Showmanship is
                  also on the line. Show us what you got and you may be taking
                  home a trophy!
                </p>
              }
              faIcon={faStore}
              iconColor="secondary"
            />
          </GridItem>
          <GridItem md={4} sm={8} xs={10} className={classes.contestsImageItem}>
            <GatsbyImage
              image={showmanshipContest.childImageSharp.gatsbyImageData}
              imgStyle={{ objectFit: "contain" }}
              className={classes.contestsImage}
              alt="Showmanship Contest"
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
